import { Link } from 'gatsby';
import React from 'react';
import thumbnailEntries from '../../static/images/feature-entries.png';
import thumbnailGuestbook from '../../static/images/feature-guestbook.png';
import thumbnailLanguage from '../../static/images/feature-language.png';
import thumbnailWidget from '../../static/images/feature-widget.png';
import featureImage from '../../static/images/hero.png';
import Layout from '../landing/components/layout';
import SEO from '../landing/components/seo';

const IndexPage = () => (
  <Layout>
    <SEO />

    <div className={'page-header home'}>
      <div className={'container'}>
        <h1>Guestbooks reimagined.</h1>
        <p>
          Let your guests share their memories by creating your own guestbook with Memorista. Embed it into your website
          using the{' '}
          <a href="https://wordpress.org/plugins/memorista/" target="_blank">
            Memorista WordPress plugin
          </a>
          , Google Tag Manager or by code. Ideal for wedding guests, hotels or events where people want to share their
          memories with you!
        </p>
      </div>
      <img alt="Memora - Guestbook reimagined." src={featureImage} />
    </div>

    <div className={'call-to-action secondary'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Live Demo</h2>
          <p>
            The best way to find out how the widget would blend in onto your site is to try it out yourself. Create your
            own guestbook or check out how we embedded it on ours.
          </p>
        </div>

        <div className={'button'}>
          <Link to="/guestbook">Go to Demo</Link>
        </div>
      </div>
    </div>

    <div className={'container'}>
      <div className={'features'}>
        <div className={'feature__item'}>
          <div className={'row'}>
            <div className={'col-6'}>
              <div className={'feature__content'}>
                <h2>Widgets</h2>
                <p>
                  Embed your Guestbook seamlessly on your Website by using the embeddable Widget.
                  <br />
                  <br />
                  Customize the title, description and language and make it blend in with your Website. Your users will
                  be able to post entries without ever leaving your Website.
                </p>
              </div>
            </div>

            <div className={'col-6 first'}>
              <div className={'thumbnail'}>
                <img alt={'Widgets'} src={thumbnailWidget} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'container'}>
      <div className={'features'}>
        <div className={'feature__item'}>
          <div className={'row'}>
            <div className={'col-6 first'}>
              <div className={'thumbnail'}>
                <img alt={'Create Guestbooks'} src={thumbnailGuestbook} />
              </div>
            </div>

            <div className={'col-6'}>
              <div className={'feature__content'}>
                <h2>Create a Guestbook</h2>
                <p>You can create your own Guestbook for any purpose in just 5 minutes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>WordPress Plugin</h2>
          <p>
            Your website runs on WordPress? No worries - you can embed your guestbook easily using the Memorista
            WordPress plugin. Try it now!
          </p>
        </div>

        <div className={'button'}>
          <a href="https://wordpress.org/plugins/memorista/" target="_blank">
            WordPress Plugin
          </a>
        </div>
      </div>
    </div>

    <div className={'container'}>
      <div className={'features'}>
        <div className={'feature__item'}>
          <div className={'row'}>
            <div className={'col-6'}>
              <div className={'feature__content'}>
                <h2>Manage Entries</h2>
                <p>
                  It's your Guestbook and Memorista gives you full control over it. In the Admin UI you can manage all
                  entries including the ability to remove undesirable submissions.
                </p>
              </div>
            </div>

            <div className={'col-6 first'}>
              <div className={'thumbnail'}>
                <img alt={'Manage Entries'} src={thumbnailEntries} />
              </div>
            </div>
          </div>
        </div>

        <div className={'feature__item'}>
          <div className={'row'}>
            <div className={'col-6 first'}>
              <div className={'thumbnail'}>
                <img alt={'Multi Language'} src={thumbnailLanguage} />
              </div>
            </div>

            <div className={'col-6'}>
              <div className={'feature__content'}>
                <h2>Hello. Hallo. Hola.</h2>
                <p>
                  Memorista speaks your language. Simply define the language of your Guestbook in the settings and the
                  Memorista Widget automatically adjusts without you having to update anything.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'call-to-action secondary'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Sign up for free</h2>
          <p>Start now and create your own personal Guestbook with Memorista in just 5 minutes.</p>
        </div>

        <div className={'button'}>
          <a href="/admin" target="_blank">
            Create Guestbook
          </a>
        </div>
      </div>
    </div>

    <div className={'container'}>
      <div className={'features'}>
        <div className={'feature__item'}>
          <div className={'feature__content'}>
            <h2>Help making Memorista even better!</h2>
            <p>Feedback and error reporting is more than welcome.</p>
            <p>
              Just fill in this{' '}
              <b>
                <a href="https://forms.gle/vyMaWtbLCULZJqit9" target="_blank">
                  quick form
                </a>
              </b>{' '}
              to provide your feedback!
            </p>
            <p>Thank you so much - your opinion means a lot to us! 😊</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
